import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import Box from '../common/components/Box';
import Text from "reusecore/Text"
import Heading from '../../reusecore/Heading';
import Container from '../common/components/UI/Container';
import styles from './tileCards.module.css'
import Fade from "react-reveal/Fade";
import {SectionBackground, SectionTitle} from "../../reusecore/SectionTitle";
import Button from "../../components/Button/Button";


const Problem = ({
  sectionWrapper,
  row,
  col,
  skillTitle,
  skillDescription,
  skillSuccessRate,
  successRateText,
}) => {

  return (
    <Box id="problem" {...sectionWrapper} className={styles.sectionWrapper}>
      <Container noGutter mobileGutter width="800px">
        <SectionTitle className={styles.sectionHeading}>

          <Fade bottom>
            <Heading> The Problem </Heading>
          </Fade>

        </SectionTitle>

        <Fade bottom>
          <Box {...row} as="div">
          <Text>
            1. Accounting is difficult but it's an unavoidable part of every company. It is a complex process that requires a lot of
            manual work and is prone to errors. It is also a very sensitive process that requires a lot of trust.
          </Text>
          </Box>
          <Box {...row} as="div">
            <Text>
             2. Current accounting models including Double Entry Accounting are outdated - over 500-years-old - and not prepared for the digital age.
            </Text>
          </Box>
          <Box {...row} as="div">
            <Text>
              3. The economy is missing accounting tools are that are easy to use, trustworthy and offer transparency for the public.
              As a result, different stakeholders are suffering from companies such as Celcius, FTX, QuadrigaCX and countless other who mismanage the risk and funds.
            </Text>
          </Box>
            <SectionTitle className={styles.sectionHeading}>

              <Link to="#daa">
                <Button>
                  Our Solution
                </Button>
              </Link>

            </SectionTitle>


          {/*<Box {...row}>*/}
          {/*  <Text>*/}
          {/*    Accounting is difficult but it's an unavoidable part of every company. It is a complex process that requires a lot of*/}
          {/*    manual work and is prone to errors. It is also a very sensitive process that requires a lot of trust.*/}
          {/*  </Text>*/}
          {/*</Box>*/}
        </Fade>
      </Container>
    </Box>
  );
};

Problem.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  skillTitle: PropTypes.object,
  skillDescription: PropTypes.object,
  skillSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

Problem.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '140px'],
    pb: ['150px', '160px', '160px', '180px', '210px'],
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: ['65px', '65px', '80px', '90px', '105px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1 / 2],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: ['30px', '30px', '30px', '50px', '50px'],
  },
  skillTitle: {
    fontSize: ['16px', '18px', '18px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '12px',
  },
  skillDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  skillSuccessRate: {
    fontSize: ['15px', '15px', '14px', '15px', '16px'],
    fontWeight: '400',
    color: '#302b4e',
    lineHeight: '1.5',
    mb: '0',
  },
  successRateText: {
    ml: '.3em',
    display: ['none', 'none', 'none', 'none', 'inline-block'],
  },
};

export default Problem;
