import React from 'react';
import { Icon } from 'react-icons-kit';
import { checkCircle } from 'react-icons-kit/fa/checkCircle';
import { timesCircle } from 'react-icons-kit/fa/timesCircle';
import Container from '../common/components/UI/Container';
import Fade from 'react-reveal/Fade'
import Box from "../common/components/Box"
import Heading from "../common/components/Heading"
import Text from "../common/components/Text"
import HeadingMain from "../../reusecore/Heading"
import TextMain from "../../reusecore/Text"
import WaveSvg from "../../assets/images/black-wave-top.svg";
import WaveSvgBottom from '../../assets/images/black-wave-bottom.svg'
import { SectionTitle, SectionBackground } from "../../reusecore/SectionTitle"
import { PricingWrapper } from './pricing.style';
import Image from "../../reusecore/Image";

const jsonData = {"data":
      {"sassMinimalJson":
            {"MONTHLY_PRICING_TABLE":[
                {
                  "isRecomended":true,
                  "quarter":"Milestone",
                    "quarterText": "In Progress",
                  "year": "2023",
                  "listItems":[
                      {
                        "isDisabled":false,
                        "content":"Webiste Launch"
                      },
                    {
                      "isLoading":true,
                      "content":"Developing MVP & MVE Concepts"
                    },
                      {
                          "isLoading":true,
                          "content":"Building Web3 Triple Entry Accounting Protocol"
                      },
                      {
                          "isLoading":true,
                          "content":"Building Web2 No-code Accounting Integrations Platform"
                      },
                    {
                      "isLoading":true,
                      "content":"Talks With High Calibre Investors"
                    },
                    // {
                    //   "isDisabled":true,
                    //   "content":"Community Marketing"
                    // },
                      {
                          "isDisabled":true,
                          "content":"Token Pre-sale & Private Sale"
                      },
                      {"isDisabled":true,"content":"Core Team Formation"},

                    ],
                },
                {
                  "isRecomended":false,
                  "quarter":"Milestone",
                  "year":"2024",
                  "quarterText": "Wait for It",
                  "listItems":[
                      {"isDisabled":true,
                        "content":"Developing project milestones with Consultants"
                      },
                    {"isDisabled":true,"content":"Developing Technical MVE and MVP Specification"},
                      {
                          "isDisabled":true,
                          "content":"Public Presale"
                      },
                    {"isDisabled":true,"content":"Further Team Hiring"},
                      {"isDisabled":true,"content":"Growing International Trippl Communities"},
                      {
                          "isDisabled":true,
                          "content":"Securing Partnerships"
                      }
                  ]},
                {
                  "isRecomended":false,
                  "quarter":"Milestone",
                    "quarterText": "Wait For It",
                  "year":"2025",
                  "listItems":
                      [
                          {"isDisabled":true,"content":"Beta Launch of DAA (MVE)"},
                        {"isDisabled":true,"content":"Beta Launch MVP (ERP system)"},
                        {"isDisabled":true,"content":"CEX Listing"},
                        {"isDisabled":true,"content":"Extensive Marketing Campaign (multichannel)"},
                        {"isDisabled":true,"content":"Publishing 2026 Roadmap"}
                      ],
                  "url":"#",
            }],
              "YEARLY_PRICING_TABLE":[{"isRecomended":false,"price":"$40.43","priceLabel":"/yr","name":"Starter Pack","listItems":[{"isDisabled":false,"content":"500 users"},{"isDisabled":false,"content":"Own analytics platform"},{"isDisabled":false,"content":"Unlimited links"},{"isDisabled":false,"content":"Chat promt supported"},{"isDisabled":true,"content":"Optimzed hashtags"}],"url":"#","buttonLabel":"Create Account"},{"isRecomended":true,"price":"$75.79","priceLabel":"/yr","name":"Standard Pack","listItems":[{"isDisabled":false,"content":"Own analytics platform"},{"isDisabled":false,"content":"Unlimited links"},{"isDisabled":false,"content":"Chat promt supported"},{"isDisabled":false,"content":"Optimzed hashtags"}],"url":"#","buttonLabel":"Create Account"},{"isRecomended":false,"price":"Let’s Talk","priceLabel":"","name":"For business enterprise","listItems":[{"isDisabled":false,"content":"10,000 users"},{"isDisabled":false,"content":"Own analytics platform"},{"isDisabled":false,"content":"Unlimited links"},{"isDisabled":false,"content":"Chat promt supported"},{"isDisabled":false,"content":"Optimzed hashtags"}],"url":"#","buttonLabel":"Create Account"}]}}}
const Pricing = () => {

  return (
      <>
          <Image src={WaveSvg} position="relative" className="bannerStyles" />
          <PricingWrapper id="pricing_section">
              <Container>
                  <Box className="blockTitle">
                      <SectionTitle>
                          <SectionBackground>
                              <Fade bottom delay={45}>
                                <HeadingMain className="mainHeading">ROADMAP</HeadingMain>
                              </Fade>
                              <Fade bottom>
                              <TextMain>So, here is how we are doing</TextMain>
                              </Fade>
                          </SectionBackground>
                      </SectionTitle>
                  </Box>
                  <Box className="row">
                      {jsonData.data.sassMinimalJson.MONTHLY_PRICING_TABLE.map(
                          (pricingTable, index) => (
                              <Box className="col" key={`price-monthly-${index}`}>
                                  <Box
                                      className={`pricingBox ${
                                          pricingTable.isRecomended === true ? 'recomended' : ''
                                      }`}
                                  >
                                      <Box className="pricingHeading">
                                          <Fade bottom>
                                            <Heading as="h3" content={pricingTable.quarter} />
                                          </Fade>
                                          <>{' '} </>
                                          <Fade bottom delay={45}>
                                            <Text as="span" content={pricingTable.year} />
                                          </Fade>
                                      </Box>

                                      <Text as="p" content={pricingTable.name} />
                                      <span className="hr" />
                                      <Box className="pricingList">
                                          {pricingTable.listItems.map((list, index) => (
                                              <Box
                                                  key={`price-monthly-list-${index}`}
                                                  className={`pricingListItem ${
                                                      list.isDisabled === true ? 'disabled' : ''
                                                  }`}
                                              >
                                                  {list.isDisabled === false ? (
                                                      <Icon icon={checkCircle} size={15} />
                                                  ) : list.isLoading === true ? (
                                                      <div className="lds-ring">
                                                          <div />
                                                          <div />
                                                          <div />
                                                          <div />
                                                      </div>
                                                  ) : (
                                                      <Icon icon={timesCircle} size={15} />
                                                  )}
                                                  <Fade bottom>
                                                  {list.content}
                                                  </Fade>
                                              </Box>
                                          ))}
                                      </Box>
                                      <div className="quarterTextContainer">
                                          <Fade bottom>
                                            <Heading mb="0" textAlign="center" as="h4" content={pricingTable.quarterText} />
                                          </Fade>
                                      </div>
                                  </Box>
                              </Box>
                          )
                      )}
                  </Box>

              </Container>
          </PricingWrapper>
          <Image src={WaveSvgBottom} position="relative" className="bannerStyles" />
      </>
  );
};

export default Pricing;
