import React from "react"
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import WalletSectionWrapper from "./wallet.style"
import {DigitalCityGraphic} from "../../components/LottieAnimations";

const Section2AnimationLeft = () => {
  return (
    <WalletSectionWrapper>
      <Container>
        <Row>
          <Col className="lg-5 md-4 xs-12">
            <Fade left>
            <Box className="wallet-image">
              {/*<img src={walletImage} alt="wallet image" />*/}
              <DigitalCityGraphic />
            </Box>
            </Fade>
          </Col>
          <Col className="lg-6 offset-lg-1 md-8 xs-12">
            <Box className="wallet-left-content">
              <SectionTitle>
                <SectionBackground>
                  <Fade bottom>
                    <Heading>
                      We make companies
                      start talking to each other
                    </Heading>
                  </Fade>
                </SectionBackground>
                <Fade bottom>
                  <Text>
                    Today only a portion of companies are able to exchange data using their ERP systems.
                    Trippl will enable every company to
                    communicate with B2B customers, suppliers, banks, or any other stakeholders on a decentralized network.
                    Any company will be able to plugin to have their accounting done "in the background" with little to no human input.
                  </Text>
                </Fade>
              </SectionTitle>

              {/*<Box className="wallet-info">*/}
              {/*  {data.wallet.map((item, index) => (*/}
              {/*    <Box className="counter-item" key={index}>*/}
              {/*      <Counter end={item.count} suffix={item.suffixText} />*/}
              {/*      <Text>{item.body}</Text>*/}
              {/*    </Box>*/}
              {/*  ))}*/}
              {/*</Box>*/}
            </Box>
          </Col>
        </Row>
      </Container>
    </WalletSectionWrapper>
  )
}

export default Section2AnimationLeft
