import React from "react";
import { Link } from "gatsby"

import { Container, Row, Col } from "../../reusecore/Layout";
import Box from "../../reusecore/Box";
import Heading from "../../reusecore/Heading";
import Text from "../../reusecore/Text";
import Image from "../../reusecore/Image";
import Button from "../../components/Button/Button"
import BannerImage from "assets/images/banners/banner-one/banner-two.svg";
import BannerImageBlack from "assets/images/banners/banner-one/banner-black.svg";
import BannerWrapper from "./banner.style";


const BannerTwo = () => {
  return (
      <>
        <Image src={BannerImageBlack} className="bannerStyles" />
        <BannerWrapper>
          <Container>
            <Row>
              <Col className="lg-7 md-10 xs-12">
                <Box className="banner-content">
                  <Heading>
                    Open Banking is here.<br/>
                    Now it's time for<br/>
                    Open Accounting.
                  </Heading>
                  <Text as="span" className="description">
                    Trippl builds Triple Entry Accounting for the Economy <br/>
                    Think "Zapier" for Accounting Software + Web3
                  </Text>
                  {/*<Box className="coin-info">*/}
                  {/*  <Box>*/}
                  {/*    <Image src={keyIcon} alt="crypto banner icon" />*/}
                  {/*    <Text>Data Integrity</Text>*/}
                  {/*  </Box>*/}
                  {/*  <Box>*/}
                  {/*    <Image src={watchIcon} alt="crypto banner icon" />*/}
                  {/*    <Text>Instant Transactions</Text>*/}
                  {/*  </Box>*/}
                  {/*  <Box>*/}
                  {/*    <Image src={heartIcon} alt="crypto banner icon" />*/}
                  {/*    <Text>Cooperation</Text>*/}
                  {/*  </Box>*/}
                  {/*</Box>*/}
                  <Link to="#problem">
                    <Button>
                        The Problem
                    </Button>
                  </Link>
                </Box>
              </Col>
            </Row>
          </Container>
        </BannerWrapper>
      </>

  );
};

export default BannerTwo;
