import styled from 'styled-components';

const ContactFromWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  position: relative;
  
  @media (max-width: 2000px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .email_input {
    flex-grow: 1;
    @media (max-width: 2000px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          color: #aeb1b6;
          font-size: 12px;
        }
      }
    }

    //input {
    //  height: 100%;
    //  background: #fff;
    //  font-size: 16px;
    //  font-weight: 500;
    //  color: #343d48;
    //  padding: 5px 15px;
    //  border-color: #dadada;
    //  border-style: solid;
    //  border-width: 2px;
    //  border-color: rgb(233, 227, 254);
    //  border-radius: 10px;
    //  background-color: rgb(243, 242, 250);
    //  height: 84px;
    //  @media (max-width: 575px) {
    //    height: 60px;
    //  }
    //}

    label {
      font-size: 14px;
      color: #343d48;
      font-weight: 500;
      padding-left: 10px;
      top: 5px;
    }
  }

  .field-wrapper {
    height: 100%;
  }
  
  button {
    position: absolute;
    right: 20px;
    border-radius: 6px;
    background-color: rgb(11, 124, 237);
    height: 60px;
    top: 12px;
    &:hover {
      background-color: #23374d;
      border-color: gb(106, 82, 253);
    }
    @media (max-width: 990px) {
      right: 40px;
    }
    @media (max-width: 2000px) {
      position: relative;
      right: 0;
      top: 0;
      height: 48px;
    }
  }
`;
const SectionMainWrapper = styled.div`
  z-index: 1;
  position: relative;
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .containerClass {
    max-width: 1000px !important;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 94px 6px rgba(107, 83, 254, 0.1);
    padding: 80px 0;

    .contactdes {
      text-align: center;
      @media (max-width: 990px) {
        padding: 0 20px;
      }
    }
  }
`;

const Right = styled.div`
  > a {
    display: inline-block;
    background-color: #108aff;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 20px 35px;
    border-radius: 30px;
    transition: all 500ms ease;
    &:hover {
      background-color: #23374d;
    }
    > i {
      margin-left: 5px;
    }
  }
`;
export { SectionMainWrapper, Right };

export default ContactFromWrapper;
