import React from "react";
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Navigation from "sections/00-Navigation";
import Intro from "sections/01-Intro";
import Problem from "sections/001-Problem";
import DaaIntro from "sections/02-DaaIntro";
import TileCards from "../sections/03-TileCardsNew"
import Section1AnimationRight from "sections/04-Section1AnimationRight";
import Section2AnimationLeft from "sections/05-Section2AnimationLeft";
import Section3AnimationRight from "sections/06-Section3AnimationRight";
import TimeLineSection from "sections/07-TimelineSection";
import NewsletterSlide from '../sections/11-Newsletter';
import Stack from "sections/Stack";
import FooterNew from '../sections/FooterNew'
import Pricing from "../sections/Pricing"
// import Team from "../sections/08-Team"
import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Home" />
      <Navigation />
      <Intro />
      <Problem />
      <DaaIntro />
      <TileCards />
      <Section1AnimationRight />
      <Stack />
        <Section2AnimationLeft />
        <Section3AnimationRight />
      <TimeLineSection />

      <Pricing />
      {/*<Team />*/}
      <NewsletterSlide />
        <FooterNew />
    </Layout>
  </ThemeProvider>
)

export default IndexPage
