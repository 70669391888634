import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import Box from '../common/components/Box';
import Text from '../common/components/Text';
import Heading from '../../reusecore/Heading';
import CardHeading from '../common/components/Heading'
import Image from '../common/components/Image';
import Container from '../common/components/UI/Container';
import styles from './tileCards.module.css'
import PicChartStats from '../../assets/images/icons/pieChartStatistics.svg'
import CalculatorDollarsHand from '../../assets/images/icons/calculatorDollarsHand.svg'
import EthChain from '../../assets/images/icons/ethChain.svg'
import TabletStatsHand from '../../assets/images/icons/tabletStatsHand.svg'
import Network from '../../assets/images/icons/network.svg'
import {
  SkillItem,
  SkillDetails,
  SkillProgress,
  SuccessRate,
  ProgressBar,
  SkillIcon,
  SkillAbout,
} from './skill.style';
import Fade from "react-reveal/Fade";
import {SectionBackground, SectionTitle} from "../../reusecore/SectionTitle";


const TileCards = ({
  sectionWrapper,
  row,
  col,
  skillTitle,
  skillDescription,
  skillSuccessRate,
  successRateText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        SKILLS {
          title
          description
          successRate
         
        }
      }
    }
  `);
  const icons = [Network, PicChartStats , TabletStatsHand, CalculatorDollarsHand, EthChain]
  return (
    <Box {...sectionWrapper} className={styles.sectionWrapper}>
      <Container noGutter mobileGutter width="1200px">
        <SectionTitle className={styles.sectionHeading}>

          <Fade bottom>
            <Heading> Change The Way You Run Your Business  </Heading>
          </Fade>

        </SectionTitle>

        <Box {...row}>
          {Data.portfolioJson.SKILLS.map((item, index) => {

            return (
                <Box {...col} key={`skill-item-${index}`}>
                  <SkillItem>
                  <Fade bottom>
                    <SkillDetails>

                      <SkillIcon>
                        <Image
                            src={icons[index]}
                            alt={`skill-icon-${index + 1}`}
                        />
                      </SkillIcon>

                      <SkillAbout>
                        <Fade bottom>
                          <CardHeading content={item.title} {...skillTitle} />
                        </Fade>
                        <Fade bottom>
                         <Text content={item.description} {...skillDescription} />
                        </Fade>
                      </SkillAbout>
                    </SkillDetails>
                  </Fade>
                    <SkillProgress>
                      <SuccessRate>
                        <Text
                            as="span"
                            content="Success Rate"
                            {...skillSuccessRate}
                            {...successRateText}
                        />
                      </SuccessRate>
                      <ProgressBar>
                        <Line
                            percent={item.successRate}
                            strokeWidth="1.8"
                            trailWidth="1.8"
                            strokeColor="#3444f1"
                            trailColor="#e3e7f2"
                        />
                      </ProgressBar>
                    </SkillProgress>
                  </SkillItem>
                </Box>
            )
          })}
        </Box>
      </Container>
    </Box>
  );
};

TileCards.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  skillTitle: PropTypes.object,
  skillDescription: PropTypes.object,
  skillSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

TileCards.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '140px'],
    pb: ['150px', '160px', '160px', '180px', '210px'],
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: ['65px', '65px', '80px', '90px', '105px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1 / 2],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: ['30px', '30px', '30px', '50px', '50px'],
  },
  skillTitle: {
    fontSize: ['16px', '18px', '18px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '12px',
  },
  skillDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  skillSuccessRate: {
    fontSize: ['15px', '15px', '14px', '15px', '16px'],
    fontWeight: '400',
    color: '#302b4e',
    lineHeight: '1.5',
    mb: '0',
  },
  successRateText: {
    ml: '.3em',
    display: ['none', 'none', 'none', 'none', 'inline-block'],
  },
};

export default TileCards;
