import React from "react"
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import {ProtectCubeGraphic} from "../../components/LottieAnimations/";
import WalletSectionWrapper from "./wallet.style"

const Section3AnimationRight = () => {
  return (
    <WalletSectionWrapper>
      <Container>
        <Row className="wrapReverse">
          <Col className="lg-6 offset-lg-1 md-8 xs-12">
            <Box className="wallet-left-content">
              <SectionTitle>
                <SectionBackground>
                    <Fade bottom>
                      <Heading> Embedded DeFi Services for regular companies

                      </Heading>
                    </Fade>
                </SectionBackground>
                  <Fade bottom>
                <Text>
                    With the help of Trippl your business will have access to DeFi services with a few clicks.

                    <br /><br />

                   - Borrowing cryptocurrencies or stablecoins at very low rates (instant approval,
                    no paperwork typically required for bank loans)
                        <br /><br />
                        - Earning interest between 5-8% on your capital to hedge against inflation
                            <br /><br />
                              - Financing your invoices or getting a credit line
                    <br /><br />
                                This and more will be accessible for your business because our technology is able to service
                                cryptocurrecy transactions and present them in meaningful, compliant way - something current accounting model cannot do.
                                Imagine platforms such as Aave or Nexo synced to Trippl infrastructure via embedded finance model so that you can access their products within seconds.

                </Text>
                  </Fade>
              </SectionTitle>

              {/*<Box className="wallet-info">*/}
              {/*  {data.wallet.map((item, index) => (*/}
              {/*    <Box className="counter-item" key={index}>*/}
              {/*      <Counter end={item.count} suffix={item.suffixText} />*/}
              {/*      <Text>{item.body}</Text>*/}
              {/*    </Box>*/}
              {/*  ))}*/}
              {/*</Box>*/}
            </Box>
          </Col>
          <Col className="lg-5 md-4 xs-12">
              <Fade right>
                <Box className="wallet-image">
                  <ProtectCubeGraphic />
                </Box>
              </Fade>
          </Col>
        </Row>
      </Container>
    </WalletSectionWrapper>
  )
}

export default Section3AnimationRight
